import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private storage: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req;
    const envStr = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    const storageData = this.storage.get<any>(envStr);

    const token = storageData ? storageData.accessToken : null;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const error = err?.error?.message || err?.message || err || "Ocurrió un Error";

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error,
        });

        if (
          err.status === 401 &&
          !this.router.url.includes('change-password')
        ) {
          this.storage.remove(envStr);
          this.router.navigateByUrl('auth/login');
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }

        return throwError(() => error);
      })
    );
  }
}
